import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="404: Not Found" description="404: Not Found" />
      <div className="relative px-4 sm:px-6 md:px-8 max-w-screen-xl mx-auto">
        <div className="py-16">
          <h1 className="text-4xl sm:text-6xl lg:text-7xl leading-tight tracking-tight text-gray-900 mb-8 sm:mb-10">
            404: Not Found
          </h1>
          <p className="max-w-4xl text-lg sm:text-2xl font-medium sm:leading-10">
            You just hit a route that doesn&#39;t exist... the sadness.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
